import * as React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Model
import { IUser } from "models/User"
// Styles
import * as styles from "./UserItem.module.css"

type UserItemProps = {
  user: IUser
}

const UserItem: React.FC<UserItemProps> = ({ user }) => {
  const { name, slug, avatar, youtube, spotify, twitter, instagram, facebook } = user
  const userAvatar = getImage(avatar.childImageSharp.gatsbyImageData)

  return (
    <div className={styles.user_item} key={name}>
      {userAvatar && (
        <Link to={`/users/${slug}/articles`}>
          <GatsbyImage className={styles.user_item__avatar} image={userAvatar} alt={name} />
        </Link>
      )}
      <h3 className={styles.user_item__name}>{name}</h3>
      <div className={styles.user_item__social_section}>
        {youtube && (
          <a
            rel="noopener noreferrer"
            target="_blank"
            className={styles.user_item__social}
            href={youtube}
            aria-label="Youtube"
          >
            <FontAwesomeIcon icon={["fab", "youtube"]} />
          </a>
        )}
        {spotify && (
          <a
            rel="noopener noreferrer"
            target="_blank"
            className={styles.user_item__social}
            href={spotify}
            aria-label="Spotify"
          >
            <FontAwesomeIcon icon={["fab", "spotify"]} />
          </a>
        )}
        {twitter && (
          <a
            rel="noopener noreferrer"
            target="_blank"
            className={styles.user_item__social}
            href={`https://twitter.com/${twitter}`}
            aria-label="Twitter"
          >
            <FontAwesomeIcon icon={["fab", "twitter"]} />
          </a>
        )}
        {instagram && (
          <a
            rel="noopener noreferrer"
            target="_blank"
            className={styles.user_item__social}
            href={`https://instagram.com/${instagram}`}
            aria-label="Instagram"
          >
            <FontAwesomeIcon icon={["fab", "instagram"]} />
          </a>
        )}
        {facebook && (
          <a
            rel="noopener noreferrer"
            target="_blank"
            className={styles.user_item__social}
            href={`https://facebook.com/${facebook}`}
            aria-label="Facebook"
          >
            <FontAwesomeIcon icon={["fab", "facebook"]} />
          </a>
        )}
      </div>
      <footer className={styles.user_item__footer}>
        <Link className={styles.user_item__view_songs} to={`/users/${slug}/articles`}>
          Ver canciones
        </Link>
      </footer>
    </div>
  )
}

export default UserItem
