import * as React from "react"
import { graphql, PageProps } from "gatsby"

// Components
import Layout from "components/Layout"
import Main from "components/Layout/Main"
import DefaultSidebar from "components/Sections/Sidebar/DefaultSidebar"
import UsersList from "components/Sections/Users/UsersList"
import Pagination from "components/UIElements/Pagination"
// Model
import { IUser } from "models/User"
import { IOptionalMetaProps } from "models/Seo"

type DataProps = {
  users: {
    nodes: IUser[]
  }
}

type PageContextProps = {
  currentPage: number
  totalPages: number
}

const BlogIndex: React.FC<PageProps<DataProps, PageContextProps>> = ({ data, pageContext }) => {
  const { currentPage, totalPages } = pageContext
  const users = data.users.nodes

  const seo: IOptionalMetaProps = {
    title: "Usuarios",
    description: "Lista de usuarios que conforman nuestra página.",
  }

  return (
    <Layout seo={seo}>
      <Main sidebar>
        <UsersList users={users} />
        <Pagination url="/users" currentPage={currentPage} totalPages={totalPages} />
      </Main>
      <DefaultSidebar />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query UsersListQuery($limit: Int!, $skip: Int!) {
    users: allUser(sort: { fields: name, order: ASC }, limit: $limit, skip: $skip) {
      nodes {
        name
        slug
        twitter
        facebook
        instagram
        youtube
        spotify
        avatar {
          childImageSharp {
            gatsbyImageData(width: 125, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
  }
`
