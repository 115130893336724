import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

// Models
import { IPage } from "models/Page"
// Components
import Sidebar from "components/Layout/Sidebar"
import Follow from "components/UIElements/Follow"
import KoFi from "./KoFi"
import Share from "components/UIElements/Share"

const DefaultSidebar: React.FC = () => {
  const pages = useStaticQuery(query).pages.nodes as IPage[]
  const path = typeof window !== "undefined" ? window.location.pathname : ""

  return (
    <Sidebar>
      <KoFi />
      <hr />
      <section className={"sidebar_card_alt"}>
        <h2 className="title">Síguenos</h2>
        <Follow />
      </section>
      <hr />

      <h2 className="title">Páginas</h2>
      <section className={"sidebar_card"}>
        {pages.map(page => {
          const { title } = page.frontmatter
          const { slug } = page.fields
          return (
            <Link key={slug} className={"sidebar_list__element"} to={`/pages${slug}`}>
              <h3 className={"sidebar_list__element_title_only_text"}>{title}</h3>
            </Link>
          )
        })}
      </section>

      <hr />

      <section className={"sidebar_card_alt"}>
        <h2 className="title">Compartir</h2>
        <Share pathname={path} />
      </section>
    </Sidebar>
  )
}

export default DefaultSidebar

const query = graphql`
  query {
    pages: allMdx(
      filter: { fields: { collection: { eq: "pages" } } }
      sort: { fields: [frontmatter___position], order: ASC }
    ) {
      nodes {
        frontmatter {
          title
          position
        }
        fields {
          slug
        }
      }
    }
  }
`
