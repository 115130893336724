import * as React from "react"

// Components
import UserItem from "./UserItem"
// Model
import { IUser } from "models/User"
// Styles
import * as styles from "./UsersList.module.css"

type UsersListProps = {
  users: IUser[]
}

const UsersList: React.FC<UsersListProps> = ({ users }) => {
  return (
    <>
      <h2 className="title">Usuarios</h2>
      <div className={styles.users}>
        {users.map(user => (
          <UserItem key={user.slug} user={user} />
        ))}
      </div>
    </>
  )
}

export default UsersList
